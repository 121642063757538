import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { DialogRef, DirectivesModule } from '@ultra/shared';

@Component({
  selector: 'ultra-mobile-disclaimer-modal',
  standalone: true,
  imports: [DirectivesModule],
  templateUrl: './mobile-disclaimer-modal.component.html',
  styleUrls: ['./mobile-disclaimer-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileDisclaimerModalComponent {
  private dialogRef = inject(DialogRef);

  public close() {
    this.dialogRef.close();
  }
}
