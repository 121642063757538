<div class="wrapper">
  <div class="message p-3 rounded-3 rounded-bottom">
    <h5>Welcome, Mobile Explorer!</h5>
    <div class="content my-3">
      <p>
        While we're on a mission to create the ultimate mobile experience, we're not quite there yet.
        <br />For the absolute best adventure, we recommend joining us on your laptop.
      </p>
      <p>
        Fear not, our team is hard at work making your mobile experience just as amazing! Thanks for your understanding
        and happy exploring!
      </p>
    </div>
    <button ultraButton (click)="close()">Show me anyway</button>
  </div>
</div>
