import { inject, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';

import { FeatureFlag, FeatureFlagService } from '../feature-flag';

@Injectable({
  providedIn: 'root',
})
export class SentryService {
  private readonly featureFlagService = inject(FeatureFlagService);

  /**
   * Identify a user
   * @param identifier
   * @param userName
   * @param userEmail
   * @param blockchainId
   */
  login(identifier: string, userName: string, userEmail: string, blockchainId: string) {
    if (this.featureFlagService.isEnabled(FeatureFlag.LOAD_SENTRY)) {
      Sentry.setUser({
        id: identifier,
        username: userName,
        email: userEmail,
        blockchainId: blockchainId,
      });
    }
  }
}
