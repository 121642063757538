<div class="container-fluid p-0">
  @if (isPlatformBrowser) {
    <ultra-navbar-layout
      [tabs]="tabs$ | async"
      [showCart]="showCart"
      [showSearchGamesForm]="(isSearchRouteActive$ | async) !== true"
      [showWallet]="showWallet"
      [showDownload]="showDownload"
      [logoUrl]="logoUrl"></ultra-navbar-layout>
  }
  <router-outlet></router-outlet>
</div>
<ultra-toast-root></ultra-toast-root>
<ultra-mobile-disclaimer />
