import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { sentryInit } from '@ultra/core/lib/services/sentry/sentry-init';
import { EnvironmentLoaderHelper } from '@ultra/environment';
import { GoogleTagManagerService } from '@ultra/shared/services';

import { AppModule } from './app/app.module';

function bootstrap() {
  EnvironmentLoaderHelper.getInstance()
    .loadConfig()
    .then((appConfig) => {
      sentryInit('ultra-store', appConfig);
      GoogleTagManagerService.generate(appConfig.gtm);
      return platformBrowserDynamic().bootstrapModule(AppModule);
    })
    .catch((err) => console.error(err));
}

if (document.readyState !== 'loading') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
