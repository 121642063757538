import { APP_INITIALIZER, inject, Provider } from '@angular/core';
import { catchError, EMPTY, filter, switchMap, tap } from 'rxjs';

import { UserFacadeService } from '../../stores/user/user-facade.service';
import { AuthService } from '../auth/auth.service';
import { FeatureFlag, FeatureFlagService } from '../feature-flag';

import { SentryService } from './sentry.service';

function sentryLogin() {
  const authService = inject(AuthService);
  const sentryService = inject(SentryService);
  const userFacadeService = inject(UserFacadeService);
  const featureFlagService = inject(FeatureFlagService);

  return () => {
    if (featureFlagService.isEnabled(FeatureFlag.LOAD_SENTRY)) {
      authService.isAuthenticated$
        .pipe(
          filter((isAuthenticated) => isAuthenticated),
          switchMap(() =>
            userFacadeService.getCurrentUser(false).pipe(
              tap((user) => sentryService.login(user.id, user.username, user.personalData.email, user.blockchainId)),
              catchError(() => EMPTY),
            ),
          ),
        )
        .subscribe();
    }
  };
}

export const SENTRY_LOGIN_PROVIDER: Provider = {
  provide: APP_INITIALIZER,
  useFactory: sentryLogin,
  multi: true,
};
