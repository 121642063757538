import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn } from '@angular/router';
import { of } from 'rxjs';

import { authGuard } from '@ultra/core/auth';
import { FeatureFlag, FeatureFlagService } from '@ultra/core/lib/services/feature-flag';
import { ClientFeatureService } from '@ultra/core/services';

export const publicStoreAuthGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
  const featureFlagService = inject(FeatureFlagService);
  const clientFeatureService = inject(ClientFeatureService);

  if (!clientFeatureService.isInClient && featureFlagService.isEnabled(FeatureFlag.PUBLIC_GAME_STORE)) {
    return of(true);
  }
  return authGuard(route, state);
};
