import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { createErrorHandler, TraceService } from '@sentry/angular-ivy';

import { SENTRY_LOGIN_PROVIDER } from './sentry-login';

@NgModule({
  providers: [
    {
      provide: ErrorHandler,
      useValue: createErrorHandler(),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => (): void => {},
      deps: [TraceService],
      multi: true,
    },
    SENTRY_LOGIN_PROVIDER,
  ],
})
export class ErrorsSentryModule {}
