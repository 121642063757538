import { ChangeDetectionStrategy, Component, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { filter, switchMap } from 'rxjs';
import { take } from 'rxjs/operators';

import { WINDOW } from '@ultra/core/providers';
import { SessionStorageService } from '@ultra/core/services';
import { Dialog, isMobile } from '@ultra/shared';

import { MobileDisclaimerModalComponent } from './mobile-disclaimer-modal/mobile-disclaimer-modal.component';

@Component({
  selector: 'ultra-mobile-disclaimer',
  standalone: true,
  imports: [],
  template: '',
  styles: ['.disable-dialog-bg ultra-dialog-container {background-color: transparent}'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileDisclaimerComponent implements OnInit {
  private STORAGE_KEY = 'mobile_disclaimer';
  private STORAGE_VALUE = 'accepted';
  private window = inject(WINDOW);
  private dialog = inject(Dialog);
  private storage = inject(SessionStorageService);

  ngOnInit() {
    const mobileView = isMobile(this.window);
    if (!mobileView) return;

    this.storage
      .get(this.STORAGE_KEY)
      .pipe(
        filter((value) => value !== this.STORAGE_VALUE),
        switchMap(() => {
          const dialogRef = this.dialog.open(MobileDisclaimerModalComponent, {
            height: '100%',
            width: '100%',
            maxWidth: '100vw',
            backdropClass: 'ultra-blur-backdrop',
            panelClass: 'disable-dialog-bg',
          });
          return dialogRef.afterClosed();
        }),
        take(1)
      )
      .subscribe(() => this.acceptMobileDisclaimer());
  }

  private acceptMobileDisclaimer() {
    this.storage.set(this.STORAGE_KEY, this.STORAGE_VALUE);
  }
}
